import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import {GettingStartedSidebar as Sidebar} from "../../components/sidebar";
import {ApiLink} from "../../components/link";
import {CodeSnippet} from "../../components/snippet";
import {InsertTutorialCode, SelectTutorialCode} from "../../components/code";
import {Callout} from "../../components/alert";
import {KeyHeader, KeyNestedHeader} from "../../components/typography";

const pageTitle = "Getting Started";

const Page = () => (
    <Layout pageTitle={pageTitle}
            sidebarNav={<Sidebar/>}
            mobileNav={false}
    >
        <h2>Getting Started with SQLAPI++ <small className="text-muted text-nowrap">Your Hello World App</small></h2>

        <p>Welcome to SQLAPI++!</p>
        <p>
            This tutorial introduces you to the essentials of SQLAPI++ by walking you through building a small
            application that makes a connection to database, inserts some data into a table, and fetches the data back.
        </p>

        <Callout className="d-none d-md-block" heading="Tip">
            Use side navigation for more in-depth explanations of different concepts and techniques, along with
            additional topics not covered by the tutorial.
        </Callout>

        <KeyNestedHeader>Basic Application Template</KeyNestedHeader>
        <CodeSnippet>{`
#include <stdio.h>  // for printf
#include <SQLAPI.h> // main SQLAPI++ header

int main(int argc, char* argv[]) {
    SAConnection con;
    
    try {
        con.Connect(_TSA("my_db"), _TSA("my_user"), _TSA("my_password"), SA_Oracle_Client);
        printf("We are connected!\\n");

        /*
        The rest of the tutorial goes here!
        */
        
        con.Disconnect();
        printf("We are disconnected!\\n");
    }
    catch(SAException &x) {
        con.Rollback();
        printf("%s\\n", x.ErrText().GetMultiByteChars());
    }
    
    return 0;
}            
            `.trim()}
        </CodeSnippet>

        <p>
            This outline for the program demonstrates the following:
        </p>
        <ul>
            <li>Include {' '}<ApiLink>SQLAPI.h</ApiLink>{' '} to get access to the library</li>
            <li>
                To connect to database, allocate a {' '}<ApiLink>SAConnection</ApiLink>{' '} object
                and use {' '}<ApiLink>SAConnection::Connect</ApiLink>{' '} method to connect to your
                database provider using your credentials
            </li>
            <li>
                Handle {' '}<ApiLink>SAException</ApiLink>{' '} exception for all database related problems
                including connectivity, database client and database server errors
            </li>
            <li>
                You can optionally use
                {' '}<ApiLink>SAConnection::Disconnect</ApiLink>{' '} to disconnect from the server.
                If you don't, the library will
                implicitly do that for you when connection object is destructed
            </li>
        </ul>

        <KeyNestedHeader>Insert Data into a Table</KeyNestedHeader>
        <InsertTutorialCode/>

        <p>This snippet of code demonstrates the following:</p>
        <ul>
            <li>
                To execute any SQL command on the server you create a
                {' '}<ApiLink>SACommand</ApiLink>{' '} object,
                passing it a valid connection object
            </li>
            <li>
                SQLAPI++ supports parameter markers to bind input data to SQL commands. This example uses
                positional parameters, but the library also supports named parameters
            </li>
            <li>
                To actually send the command to the server use {' '}<ApiLink>SACommand::Execute</ApiLink>{' '} method
            </li>
        </ul>

        <KeyNestedHeader>Read Data from a Table</KeyNestedHeader>
        <SelectTutorialCode/>

        <p>In this code snippet:</p>
        <ul>
            <li>You use {' '}<ApiLink>SACommand</ApiLink>{' '} again, this time for SELECT statement</li>
            <li>You use parameter binding, just like we did for INSERT command</li>
            <li>Use {' '}<ApiLink>SACommand::Execute</ApiLink>{' '} to send your query to the server</li>
            <li>{' '}<ApiLink>SACommand::FetchNext</ApiLink>{' '} method is used to fetch results set row by row</li>
            <li>
                You access SELECT fields in the result set using field access operators.
                This example uses positional operators but the library also supports access by name
            </li>
        </ul>

        <Callout heading="Congrats!">
            This is it - you just mastered the basics!
        </Callout>

        <KeyHeader>Next Steps</KeyHeader>
        <div>Explore Getting Started topics in greater depth:</div>
        <ul>
            <li><Link to="/HowTo/connect/">Connect to database</Link></li>
            <li><Link to="/HowTo/transactions/">Transactions</Link></li>
            <li><Link to="/HowTo/commands/">Execute SQL command</Link></li>
            <li><Link to="/HowTo/procedures/">Call stored procedure</Link></li>
            <li><Link to="/HowTo/bind/">Bind input parameters</Link></li>
            <li><Link to="/HowTo/fetch/">Fetch result set(s)</Link></li>
            <li><Link to="/HowTo/performance/">Bulk Fetching</Link></li>
            <li><Link to="/HowTo/return_param/">Get output parameters or function return value</Link></li>
            <li><Link to="/HowTo/blobs/">Handle Long/CLob/BLob</Link></li>
            <li><Link to="/HowTo/datetime/">Work with date/time</Link></li>
            <li><Link to="/HowTo/errors/">Error handling</Link></li>
            <li><Link to="/HowTo/native_api/">Use native API</Link></li>
            <li><Link to="/HowTo/query_example/">Query examples</Link></li>
        </ul>

        <p>
            Check out complete <Link to="/ApiDoc/">API documentation</Link>{' '}
            or {' '}<Link to="/Support/faq/">FAQ</Link>{' '} section.
        </p>
    </Layout>
);

export default Page;
